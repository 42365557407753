import {
  ContinueFlowQuestion,
  OptionType,
  QUESTION_TYPES_ENUM,
  QuestionPage,
  UserAnswersFlow,
  UserAnswersPage,
  clearResponsesCallback,
  continueFlowCallback,
  continueFlowState,
  currentUserState,
  evalActionCallback,
  getAllQuestionPageCallback,
  getDescriptionFromAnswer,
  isStepThreeCompleted,
  resetContinueFlowStateCallback,
  useBreakpoint,
} from '@laborability/commons';
import {
  COLORS,
  IconHappyFaceComponent,
  IconsSize,
  IconsStyle,
  LBTAlert,
  LBTButton,
  LBTDialog,
  LBTDivider,
  LBTLabel,
  LBTListItem,
  LBTSpacer,
  Section,
} from '@laborability/components';
import { Box, Stack } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { APP_ROUTES } from '../../Routes';
import { Fragment, useEffect, useState } from 'react';
import { useRecoilCallback, useRecoilValue } from 'recoil';
import { Answer } from '@laborability/components/src/components/Questionary/Page';

interface Props {
  flow: UserAnswersFlow;
  onClose: () => void;
}

export default function AnswerReview({ flow, onClose }: Props) {
  const { isDesktop } = useBreakpoint();
  const navigate = useNavigate();
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState<boolean>(false);
  const clearResponses = useRecoilCallback(clearResponsesCallback, []);
  const getPage = useRecoilCallback(continueFlowCallback, []);
  const [values, setValues] = useState<Answer[]>([]);
  const [stepThreeAnswers, setStepThreeAnswers] = useState<UserAnswersPage[]>(
    [],
  );
  const [stepThreeQuestions, setStepThreeQuestions] = useState<QuestionPage[]>(
    [],
  );
  const [stepThreeFetched, setStepThreeFetched] = useState(false);
  const currentUser = useRecoilValue(currentUserState);
  const pageData = useRecoilValue(continueFlowState);
  const resetPageState = useRecoilCallback(resetContinueFlowStateCallback, []);
  const evalAction = useRecoilCallback(evalActionCallback, []);
  const getAllPage = useRecoilCallback(getAllQuestionPageCallback, []);
  const actionId = pageData.actions.filter(
    action =>
      action.default_target_page_id &&
      action.page_id !== action.default_target_page_id,
  )[0]?.id;
  const sortedPages = [...flow.pages].sort(
    (a, b) => b.page_order - a.page_order,
  );

  const isStepCompleted = (step: number) => {
    return currentUser.questionary_status?.some(
      questionary => questionary.completed && questionary.step === step,
    );
  };

  const getNextPage = async (flowId: number, pageId: number) => {
    const res = await getPage({ flow_id: flowId, page_id: pageId });
    if (res?.data) {
      setValues(
        res.data?.questions
          ?.filter((item: ContinueFlowQuestion) => item.question.visible)
          ?.map((item: ContinueFlowQuestion) => ({
            question_id: item.question_id,
            entity_id: item.entity_id,
            answer_value: item.answer_value,
            question_type: item.question.question_type,
          })) ?? [],
      );
    }
  };

  const getPages = async (id: number) => {
    const res = await getAllPage({ id: id });
    const questionPage: QuestionPage[] = res?.data.items;
    const resolvedQuestionPages = await Promise.all(questionPage);
    const pages = resolvedQuestionPages.flat();
    return pages as QuestionPage[];
  };

  useEffect(() => {
    if (!stepThreeFetched) {
      if (flow.step === 3) {
        (async () => {
          setStepThreeQuestions(await getPages(flow.flow_id));
          setStepThreeAnswers(flow.pages);
          setStepThreeFetched(true);
        })();
      }
    }
  }, [flow]);

  useEffect(() => {
    if (flow.step !== 3 && !isStepCompleted(flow.step) && !!sortedPages.length)
      getNextPage(flow.flow_id, sortedPages[0].page_id);
  }, []);

  return (
    <>
      <Section>
        <LBTSpacer spacing={2} />
        <Stack
          direction="row"
          alignItems="center"
          component="a"
          sx={{ cursor: 'pointer' }}
        >
          <svg
            width="9"
            height="9"
            viewBox="0 0 9 9"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M3.85618 7.195L0.307177 3.75V3.698L3.85618 0.252999L4.40218 0.851L1.78918 3.282H7.49618V4.166H1.78918L4.40218 6.597L3.85618 7.195Z"
              fill={COLORS.getInstance().PRIMARY_SUPERDARK}
            />
          </svg>
          <LBTLabel
            variant="smallCaps"
            color={COLORS.getInstance().PRIMARY_SUPERDARK}
            onClick={onClose}
          >
            Questionario
          </LBTLabel>
        </Stack>
        <LBTSpacer spacing={1} />
        <LBTLabel variant="delaDisplay" component="h1">
          {flow.flow_name}
        </LBTLabel>
        <LBTSpacer spacing={4} />
        {flow.pages.map((page, pageIndex) => (
          <Fragment key={page.page_id}>
            {page.answers
              .slice()
              .sort((a, b) => a.entity_id - b.entity_id)
              .map((answer, answerIndex) => (
                <LBTListItem
                  key={`${answer.question_id}-${answer.entity_id}`}
                  title={answer.label ?? answer.attribute_name}
                  description={getDescriptionFromAnswer(
                    answer.answer,
                    answer.answer_type,
                  )}
                  sx={{ maxWidth: '680px', padding: '8px 16px' }}
                  rightItem={
                    answerIndex === 0 ? (
                      <LBTLabel
                        variant="bodyText"
                        component="a"
                        onClick={() =>
                          navigate(`/${APP_ROUTES.QUESTIONARY}`, {
                            state: { step: flow.step, pageId: page.page_id },
                          })
                        }
                        style={{
                          textDecoration: 'underline',
                          fontWeight: 'bold',
                        }}
                      >
                        Modifica
                      </LBTLabel>
                    ) : undefined
                  }
                />
              ))}

            {flow.pages.length !== pageIndex + 1 && (
              <LBTDivider sx={{ maxWidth: '680px', width: '100%' }} />
            )}
          </Fragment>
        ))}
        <LBTSpacer spacing={2} />
        {flow?.image && (
          <Box
            style={{
              width: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <img
              style={{
                width: '100%',
                maxWidth: !isDesktop ? '200px' : '300px',
              }}
              src={flow.image}
              alt=""
            />
          </Box>
        )}
        <LBTSpacer spacing={2} />

        {flow.step === 3 &&
          !isStepThreeCompleted(stepThreeAnswers, stepThreeQuestions) && (
            <>
              <LBTAlert
                title="Bonus non verificabili"
                message="Ci sono tante agevolazioni legate alle super domande ancora in sospeso. Se hai saltato alcune super domande, rispondi anche solo con una stima per sbloccare queste agevolazioni"
                variant="standard"
                color="error"
              />
              <LBTSpacer spacing={2} />
              <LBTButton
                variant="contained"
                fullWidth
                onClick={() => {
                  navigate(`/${APP_ROUTES.QUESTIONARY}`, {
                    state: { step: 3, pageId: 0 },
                  });
                }}
                maxWidth="504px"
              >
                Rispondi alle super domande
              </LBTButton>
            </>
          )}

        {flow.step !== 3 && !isStepCompleted(flow.step) && (
          <>
            <LBTAlert
              title={`Completamento ${Math.round((flow.completed_pages * 100) / flow.total_pages)}%`}
              message="Continua a rispondere alle domande della categoria per sbloccare tutte le agevolazioni che ti spettano"
              variant="standard"
              color="warning"
            />
            <LBTSpacer spacing={2} />
            <LBTButton
              variant="contained"
              fullWidth
              onClick={async () => {
                if (!sortedPages.length) {
                  navigate(`/${APP_ROUTES.QUESTIONARY}`, {
                    state: {
                      step: flow.step,
                      pageId:
                        flow.pages.length > 0
                          ? currentUser.questionary_status?.find(
                              questionary => questionary.step === flow.step,
                            )?.page_id ?? 0
                          : 0,
                    },
                  });
                  return;
                }
                const filteredQuestion = values.filter(
                  value =>
                    (value.question_type === QUESTION_TYPES_ENUM.switch ||
                      value.answer_value !== null) &&
                    pageData.questions.find(
                      item =>
                        item.question_id === value.question_id &&
                        item.entity_id === value.entity_id,
                    )?.question.visible,
                );
                const answer = filteredQuestion.map(value => {
                  if (
                    value.answer_value === '' &&
                    value.question_type === QUESTION_TYPES_ENUM.radio
                  ) {
                    return {
                      ...value,
                      answer_value: { type: OptionType.domain, value: [] },
                    };
                  }
                  if (
                    !value.answer_value &&
                    value.question_type === QUESTION_TYPES_ENUM.switch
                  ) {
                    return {
                      ...value,
                      answer_value: false,
                    };
                  }
                  return value;
                });

                resetPageState();
                const res = await evalAction({
                  flow_id: flow.flow_id,
                  page_id: sortedPages[0].page_id,
                  action_id: actionId!,
                  questions: answer,
                });
                if (res?.data?.target_page_id) {
                  navigate(`/${APP_ROUTES.QUESTIONARY}`, {
                    state: {
                      step: flow.step,
                      pageId:
                        flow.pages.length > 0
                          ? res.data.target_page_id ?? 0
                          : 0,
                    },
                  });
                } else {
                  navigate(`/${APP_ROUTES.QUESTIONARY}`, {
                    state: {
                      step: flow.step,
                      pageId:
                        flow.pages.length > 0
                          ? currentUser.questionary_status?.find(
                              questionary => questionary.step === flow.step,
                            )?.page_id ?? 0
                          : 0,
                    },
                  });
                }
              }}
              maxWidth="504px"
              size="large"
            >
              Continua a rispondere
            </LBTButton>
          </>
        )}

        <LBTSpacer spacing={2} />
        <div style={{ maxWidth: '504px', display: 'flex', gap: '8px' }}>
          <div>
            <IconHappyFaceComponent
              size={IconsSize.LARGE}
              style={IconsStyle.OUTLINE}
              color={COLORS.getInstance().SUCCESS_SUPERDARK}
            />
          </div>
          <LBTLabel
            variant="inputHelper"
            color={COLORS.getInstance().SUCCESS_SUPERDARK}
            textAlign="left"
          >
            Le informazioni che ci dai sono preziose e le tratteremo con la
            massima riservatezza. I dati che raccogliamo servono a selezionare
            le agevolazioni giuste per te. Nessuno (nemmeno noi) potrà mai
            risalire alla tua identità
          </LBTLabel>
        </div>
        <LBTSpacer spacing={4} />
        <LBTButton
          onClick={() => setIsDeleteModalOpen(true)}
          variant="invisible"
        >
          Elimina i dati di questa sezione
        </LBTButton>
        <LBTSpacer spacing={6} />
      </Section>
      <LBTDialog
        open={isDeleteModalOpen}
        onClose={() => setIsDeleteModalOpen(false)}
        title="Confermi di voler eliminare i dati di questa sezione?"
        onSubmitLabel="Elimina dati"
        onSubmit={async () => {
          await clearResponses({ id: flow.flow_id });
          resetPageState();
          setIsDeleteModalOpen(false);
          onClose();
        }}
      >
        Cancelleremo tutte le risposte della categoria, comprese persone,
        veicoli o immobili che hai creato in questa sezione e tutti i dati a
        loro collegati. Se vuoi sbloccare le agevolazioni di questa sezione,
        dovrai rispondere di nuovo alle domande del questionario.
      </LBTDialog>
    </>
  );
}
