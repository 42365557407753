import dayjs from 'dayjs';
import {
  ActionTypeEnum,
  Category,
  QuestionPage,
  User,
  UserAnswersFlow,
  UserAnswersPage,
} from '../recoil';
import { APP_ROUTES } from './constants';
import { NavigateFunction } from 'react-router-dom';

export const getUUID = () => {
  let d = new Date().getTime(); //Timestamp
  let d2 =
    (typeof performance !== 'undefined' &&
      performance.now &&
      performance.now() * 1000) ||
    0; //Time in microseconds since page-load or 0 if unsupported
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    let r = Math.random() * 16; //random number between 0 and 16
    if (d > 0) {
      //Use timestamp until depleted
      r = (d + r) % 16 | 0;
      d = Math.floor(d / 16);
    } else {
      //Use microseconds since page-load if supported
      r = (d2 + r) % 16 | 0;
      d2 = Math.floor(d2 / 16);
    }
    return (c === 'x' ? r : (r & 0x3) | 0x8).toString(16);
  });
};

export const getBase64 = (file: File) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });
};

export const acceptedImageType = {
  accept: 'image/png, image/gif, image/jpeg, image/svg+xml',
};

const getParentCategories = (
  pAllCategories: Array<Category>,
  currentCategory: Category,
  level: number,
  mainParentId: number,
  orderedCategories: Array<Category>,
) => {
  // currentCategory.name =
  //   (level > 0 ? '|' : '') + '--'.repeat(level) + currentCategory.name;

  const exist = orderedCategories.find(
    (e: Category) => e.id === currentCategory.id,
  );
  !exist && orderedCategories.push(currentCategory);

  const subCategories = pAllCategories.filter(
    (c: Category) => c.parent_id === currentCategory.id,
  );
  if (subCategories.length > 0) {
    currentCategory.level = level;
    currentCategory.mainParentId = mainParentId;
    currentCategory.subCategories = subCategories;
    level++;
    subCategories.forEach((subCat: Category) => {
      getParentCategories(
        pAllCategories,
        subCat,
        level,
        mainParentId,
        orderedCategories,
      );
    });
  } else {
    currentCategory.level = level;
    currentCategory.mainParentId = mainParentId;
    currentCategory.subCategories = new Array<Category>();
  }
};

export const getAllCategoriesWithSubcategories = (
  originalCategories: Array<Category>,
) => {
  const copyOfCategories = JSON.parse(JSON.stringify(originalCategories));

  const categoriesWithSubcategories = copyOfCategories
    .slice()
    .filter((c: Category) => !c.parent_id);

  const orderedCategories = new Array<Category>();

  categoriesWithSubcategories.forEach((c: Category) => {
    getParentCategories(
      copyOfCategories,
      c,
      0,
      c.id as number,
      orderedCategories,
    );
  });

  return {
    originalCategories,
    copyOfCategories,
    categoriesWithSubcategories,
    orderedCategories,
  };
};

export function removeNullishOrEmptyFields(obj: any) {
  return Object.fromEntries(
    Object.entries(obj).filter(
      ([_, value]) => value !== null && value !== undefined && value !== '',
    ),
  );
}

export function getRealmFromUrl() {
  const hostname = window.location.hostname.split('.');
  console.log({
    hostname: hostname[0],
    split: hostname[0].split('-')[0],
    window,
  });
  if (hostname.length === 3) return hostname[0].split('-')[0];
  return undefined;
}

export function slugify(str: string) {
  return String(str)
    .normalize('NFKD') // split accented characters into their base characters and diacritical marks
    .replace(/[\u0300-\u036f]/g, '') // remove all the accents, which happen to be all in the \u03xx UNICODE block.
    .trim() // trim leading or trailing whitespace
    .toLowerCase() // convert to lowercase
    .replace(/[^a-z0-9 -]/g, '') // remove non-alphanumeric characters
    .replace(/\s+/g, '-') // replace spaces with hyphens
    .replace(/-+/g, '-'); // remove consecutive hyphens
}

export function checkQuestionaryAccess(realm: string) {
  return !!process.env.REACT_APP_FE_QUESTIONARY_ACCESS?.split(', ').find(
    azienda => azienda === realm,
  );
}

export function checkUserDetailsAccess() {
  return process.env.REACT_APP_ENV !== 'prod';
}

export function getDescriptionFromAnswer(answer: string, type: string) {
  if (dayjs(answer, 'DD-MM-YYYY HH:mm:ss', true).isValid())
    return dayjs(answer, 'DD-MM-YYYY HH:mm:ss').format('DD/MM/YYYY');
  else if (answer === 'True' && type === 'bool') return 'Sì';
  else if (answer === 'False' && type === 'bool') return 'No';
  else return answer;
}

export async function resumeQuestionary(
  currentUser: User,
  getPages: (id: number) => Promise<QuestionPage[]>,
  getAllAnswers: (step: number) => Promise<UserAnswersFlow | undefined>,
  navigate: NavigateFunction,
) {
  const firstSteps = [1, 2, 3];

  const startedSteps =
    currentUser.questionary_status
      ?.map(item => ({
        step: item.step,
        completed: item.completed,
      }))
      .sort((a, b) => a.step! - b.step!) ?? [];

  const missingSteps: number[] = [];

  for (let step = 1; step <= firstSteps.length; step++) {
    if (startedSteps.map(item => item.step).indexOf(step) === -1) {
      missingSteps.push(step);
    }
  }

  missingSteps.sort((a, b) => a - b);

  const stepThree = currentUser.questionary_status?.find(
    flow => flow.step === 3,
  );
  const stepThreeQuestion = stepThree ? await getPages(stepThree.flow_id!) : [];
  const stepThreeAnswers = stepThree ? await getAllAnswers(3) : undefined;

  const unfinshedSteps = await Promise.all(
    currentUser.questionary_status
      ?.filter(item =>
        item.step === 3
          ? !isStepThreeCompleted(stepThreeAnswers?.pages!, stepThreeQuestion)
          : item.completed === false,
      )
      .map(async item => {
        if (item.step === 3) {
          if (
            !currentUser.questionary_status?.find(flow => flow.step === 3)
              ?.total_responses
          ) {
            return {
              step: item.step,
              pageId: 0,
            };
          }
          const skipPages = stepThreeQuestion.filter(page =>
            page.actions?.some(
              action => action.action_type === ActionTypeEnum.skip,
            ),
          );
          const unrespondedAnswers = skipPages
            .filter(
              page =>
                !stepThreeAnswers?.pages
                  .map(answer => answer.page_id)
                  .includes(page.id!),
            )
            .sort((a, b) => a.page_order! - b.page_order!);
          if (unrespondedAnswers.length) {
            return {
              step: item.step,
              pageId: unrespondedAnswers[0].id,
            };
          }
        }
        return {
          step: item.step ?? 1,
          pageId: item.page_id,
        };
      }) ?? [],
  );

  unfinshedSteps.sort((a, b) => a.step! - b.step!);

  const isFirstStepsMissing = firstSteps.some(item =>
    missingSteps.includes(item),
  );
  const isFirstStepsUnfinished = firstSteps.some(item =>
    unfinshedSteps.map(item => item.step).includes(item),
  );

  if (startedSteps.length === 0) {
    /// utente non ha iniziato nessun questionario
    navigate(`/${APP_ROUTES.QUESTIONARY}`); /// inizia il questionario da zero
  } else {
    /// utente ha iniziato almeno un questionario
    if (missingSteps.length > 0) {
      /// utente ha saltato dei questionari
      if (isFirstStepsMissing) {
        /// uno dei questionari saltati è Q1, Q2 o Q3
        if (unfinshedSteps.length > 0 && isFirstStepsUnfinished) {
          /// utente ha un questionario tra Q1, Q2 o Q3 incompleto
          if (missingSteps[0] < unfinshedSteps[0].step) {
            /// parti dal primo questionario saltato tra Q1, Q2 o Q3
            navigate(`/${APP_ROUTES.QUESTIONARY}`, {
              state: {
                step: missingSteps[0],
                pageId: 0,
              },
            });
          } else {
            /// parti dal primo questionario incompleto tra Q1, Q2 o Q3
            navigate(`/${APP_ROUTES.QUESTIONARY}`, {
              state: {
                step: unfinshedSteps[0].step,
                pageId: unfinshedSteps[0].pageId,
              },
            });
          }
        } else {
          /// utente non ha nessun questionario tra Q1, Q2 o Q3 incompleto, solo saltati
          navigate(`/${APP_ROUTES.QUESTIONARY}`, {
            state: {
              step: missingSteps[0],
              pageId: 0,
            },
          }); /// vai al primo questionario saltato
        }
      } else {
        /// uno dei questionari saltati non è Q1, Q2 o Q3
        if (unfinshedSteps.length > 0) {
          /// utente ha dei questionari incompleti
          navigate(`/${APP_ROUTES.QUESTIONARY}`, {
            state: {
              step: unfinshedSteps[0].step,
              pageId: unfinshedSteps[0].pageId,
            },
          }); /// vai al primo questionario incompleto
        } else {
          /// utente non ha nessun questionario incompleto, solo saltati
          navigate(`/${APP_ROUTES.QUESTIONARY}`, {
            state: {
              step: missingSteps[0],
              pageId: 0,
            },
          }); /// vai al primo questionario saltato
        }
      }
    } else {
      /// utente non ha saltato nessun questionario
      if (unfinshedSteps.length > 0) {
        /// utente ha dei questionari incompleti
        navigate(`/${APP_ROUTES.QUESTIONARY}`, {
          state: {
            step: unfinshedSteps[0].step,
            pageId: unfinshedSteps[0].pageId,
          },
        }); /// vai al primo questionario incompleto
      } else {
        navigate(`/${APP_ROUTES.QUESTIONARY}`, {
          state: {
            step: 0,
            pageId: 0,
          },
        }); /// vai al menu del questionario
      }
    }
  }
}

export function isStepThreeCompleted(
  answers: UserAnswersPage[],
  questions: QuestionPage[],
) {
  const requiredQuestions = questions.filter(question =>
    question.actions?.some(
      action => action.action_type === ActionTypeEnum.skip,
    ),
  );
  const answeredQuestionIds = answers.flatMap(answer => answer.page_id);

  return requiredQuestions.every(question =>
    answeredQuestionIds.includes(question.id!),
  );
}
